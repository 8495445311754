<template>
  <div>
    <div class="row">
      <div class="col-m-6">
        <table class="table">
          <tr>
            <th></th>
            <th>PRODUCTO</th>
            <th>ATA</th>
            <th>CCPLAZA</th>
            <th>KARI</th>
          </tr>
          <tr v-for="(l, index) in list" :key="index">
            <td>
              <img :src="l.img" alt="" />
            </td>
            <td>
              {{ l.name }}
              <br />
              <strong
                ><app-span-money
                  :quantity="l.unit_price"
                  :moneyCode="l.money_code"
                ></app-span-money
              ></strong>
            </td>
            <template v-for="l1 in l.stock">
              <td :key="l1.store_id">
                <div class="btn-group">
                  <button class="btn">{{ l1.units }}</button>
                  <button class="btn btn-light" @click="l1.units++">
                    <i class="fa fa-plus"></i>
                  </button>
                  <button class="btn btn-light" @click="l1.units--">
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </td>
            </template>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    list: [
      {
        img: "../../assets/img/1.PNG",
        // src\assets\img\1.PNG
        name: "VIZZANO NEGRO-juanete Taco 4",
        unit_price: "150",
        money_code: 1,
        size: 34,
        stock: [
          { store_id: 1, units: 1 },
          { store_id: 2, units: 2 },
          { store_id: 3, units: 0 }
        ]
      },
      {
        img: "../../assets/img/1.PNG",
        name: "VIZZANO CAFE-juanete Taco 4",
        unit_price: "150",
        money_code: 1,
        size: 34,
        stock: [
          { store_id: 1, units: 1 },
          { store_id: 2, units: 2 },
          { store_id: 3, units: 0 }
        ]
      },
      {
        img: "../../assets/img/1.PNG",
        name: "VIZZANO AZUL-juanete Taco 4",
        unit_price: "150",
        money_code: 1,
        size: 34,
        stock: [
          { store_id: 1, units: 1 },
          { store_id: 2, units: 2 },
          { store_id: 3, units: 0 }
        ]
      },
      {
        img: "../../assets/img/1.PNG",
        name: "VIZZANO CUÑA Abierto",
        unit_price: "150",
        money_code: 1,
        size: 34,
        stock: [
          { store_id: 1, units: 1 },
          { store_id: 2, units: 2 },
          { store_id: 3, units: 0 }
        ]
      },
      {
        img: "../../assets/img/1.PNG",
        name: "VIZZANO Cuña LIGADA",
        unit_price: "150",
        money_code: 1,
        size: 34,
        stock: [
          { store_id: 1, units: 1 },
          { store_id: 2, units: 2 },
          { store_id: 3, units: 0 }
        ]
      },
      {
        img: "../../assets/img/1.PNG",
        name: "VIZZANO CUÑA negro",
        unit_price: "150",
        money_code: 1,
        size: 34,
        stock: [
          { store_id: 1, units: 1 },
          { store_id: 2, units: 2 },
          { store_id: 3, units: 0 }
        ]
      }
    ]
  })
};
</script>

<style></style>
